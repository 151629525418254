<template>
  <div>
    <v-app-bar flat app clipped-left dark color="#F0C316">
      <!-- <v-app-bar-nav-icon @click.stop="drawerToggle" /> -->

      <v-toolbar-title
        class="mr-12 align-center pointer"
        @click="$router.push('/')"
      >
        <span
          class="pl-2 mr-2 font-weight-light"
          v-if="!$vuetify.breakpoint.xsOnly"
          style="color: white; font-size: 25px;"
          >smpl <span class="font-weight-black black--text">sale</span>
        </span>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-btn text @click="$router.push('/')">
        Home
      </v-btn>
      <!-- <v-btn text @click="$router.push('/pricing')">
        Pricing
      </v-btn> -->
      <v-divider vertical> </v-divider>
      <v-btn text @click="$router.push('/signup')">
        Sign Up
      </v-btn>

      <v-btn text @click="$router.push('/login')">
        Login
      </v-btn>
    </v-app-bar>
    <v-content>
      <v-container grid-list-md>
        <v-row id="price">
          <v-col>
            <v-card class="pa-10" flat>
              <v-card-text>
                <div class="text-center">
                  <div class="display-2 font-weight-black">
                    Price
                  </div>
                  <div class="title">Choose your plan</div>
                  <div class="title">No Contract you can cancel any time</div>
                </div>
              </v-card-text>
            </v-card>
            <v-container>
              <v-row justify="center">
                <v-col>
                  <PriceCard :price="plan1.price" :color="plan1.color" :dark="plan1.dark">
                    <slot>
                      <v-list>
                        <v-list-item v-for="(item, index) in plan1.items" :key="index">
                          <v-list-item-icon>
                            <v-icon v-if="item.icon" :color="item.color">{{ item.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </slot>
                  </PriceCard>
                </v-col>
                <v-col>
                  <PriceCard :price="plan2.price" :color="plan2.color" :dark="plan2.dark">
                    <slot>
                      <v-list>
                        <v-list-item v-for="(item, index) in plan2.items" :key="index">
                          <v-list-item-icon>
                            <v-icon v-if="item.icon" :color="item.color">{{ item.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </slot>
                  </PriceCard>
                </v-col>
                <v-col>
                  <PriceCard :price="plan3.price" :color="plan3.color" :dark="plan3.dark">
                    <slot>
                      <v-list>
                        <v-list-item v-for="(item, index) in plan3.items" :key="index">
                          <v-list-item-icon>
                            <v-icon v-if="item.icon" :color="item.color">{{ item.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </slot>
                  </PriceCard>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-10">
                <div class="text-center display-3 font-weight-thin">
                  Try for free, First month on Us
                </div>
              </v-row>
              <v-row justify="center">
                <v-btn
                  class="mt-10"
                  @click="signup"
                  large
                  color="success"
                  >Sign Up Now</v-btn
                >
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import GlobalFunction from "@/mixins/GlobalFunction";
import contactUsService from "./service";
import PriceCard from "@/views/components/PriceCard.vue";
const { validationMixin } = require("vuelidate");
const { email, required } = require("vuelidate/lib/validators");

export default {
  data: () => ({
    plan1: {
      price: '$19.99',
      color: '#42576C',
      dark: true,
      items: [
        {name: 'Inventory Control', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'CRM', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Reports', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: '1 User Account', icon: 'mdi-progress-check', color: 'green accent-3'},
        {name: '150 Products', icon: 'mdi-progress-check', color: 'green accent-3'},
        {name: '20 Categories', icon: 'mdi-progress-check', color: 'green accent-3'},
        {name: '100 Estimets', icon: 'mdi-progress-check', color: 'green accent-3'},
        {name: '100 Invoices every month', icon: 'mdi-progress-check', color: 'green accent-3'},      
        {name: 'Expense Tickets', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
        {name: 'Email Invoice', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
        {name: 'Purchase Order', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
        {name: 'Leads Managment', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
        {name: 'Catalog Ordering', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
        {name: 'Serialize Inventory', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
        {name: 'Remote Ordering', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
        {name: 'Cloud Doucment Storage', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
      ]
    },
    plan2: {
      price: '$79.99',
      color: 'yellow accent-3',
      dark: false,
      items: [
        {name: 'Inventory Control', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'CRM', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Reports', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: '3 User Account', icon: 'mdi-progress-check', color: 'green accent-3'},
        {name: 'Unlimited Products', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Unlimited Categories', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: '200 Estimets', icon: 'mdi-progress-check', color: 'green accent-3'},
        {name: '200 Invoices every month', icon: 'mdi-progress-check', color: 'green accent-3'},      
        {name: '50 Expense Tickets', icon: 'mdi-progress-check', color: 'green accent-3'},
        {name: 'Email Invoice', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Purchase Order', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Leads Managment', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Catalog Ordering', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Serialize Inventory', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Remote Ordering', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
        {name: 'Cloud Doucment Storage', icon: 'mdi-close-circle-outline', color: 'red darken-1'},
      ]
    },
    plan3: {
      price: '$124.99',
      color: 'light-green accent-4',
      dark: false,
      items: [
        {name: 'Inventory Control', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'CRM', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Reports', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Unlimited User Account',icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Unlimited Products', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Unlimited Categories',icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Unlimited Estimets', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Unlimited Invoices', icon: 'mdi-check-circle-outline', color: 'green accent-4'},      
        {name: 'Expense Module',icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Email Invoice',icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Purchase Order', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Leads Managment', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Catalog Ordering',icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Serialize Inventory', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Remote Ordering', icon: 'mdi-check-circle-outline', color: 'green accent-4'},
        {name: 'Cloud Doucment Storage',icon: 'mdi-check-circle-outline', color: 'green accent-4'},
      ]
    },
    dialog: false,
    customer: {
      name: null,
      phoneNumber: null,
      emailAddress: null,
      message: null,
    },
  }),
  mixins: [validationMixin, GlobalFunction],
  validations: {
    customer: {
      name: {
        required,
      },
      phoneNumber: {
        required,
      },
      emailAddress: {
        required,
        email,
      },
    },
  },
  components: {
    PriceCard,
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) return errors;
      if (!this.$v.customer.name.required) errors.push("Name is required.");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.customer.phoneNumber.$dirty) return errors;
      if (!this.$v.customer.phoneNumber.required)
        errors.push("Phone number is required.");
      return errors;
    },
    emailAddressErrors() {
      const errors = [];
      if (!this.$v.customer.emailAddress.$dirty) return errors;
      if (!this.$v.customer.emailAddress.required)
        errors.push("Email is required.");
      return errors;
    },
  },
  methods: {
    signup() {
      window.open("https://app.smplsale.com/signup","_self");
    },
    close() {
      this.dialog = false;
      this.$router.push("/");
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        return contactUsService
          .create(this.customer)
          .then(() => {
            this.dialog = true;
          })
          .catch((err) => {
            console.log("err", err);
            // this.$swal
            //   .mixin({
            //     toast: true,
            //     position: "top-end",
            //     showConfirmButton: false,
            //     timer: 3000,
            //   })
            //   .fire({
            //     icon: "error",
            //     title: err.data.message,
            //   });
          });
      }
    },
  },
};
</script>
