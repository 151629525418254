<template>
  <v-card
      outlined
      flat
      :elevation="hover ? 20 : 0"
      class="mx-auto"
    >
      <v-card
        tile
        flat :dark="dark"
        class="font-weight-bold"
        :color="color"
      >
        <div class="pa-10 text-center ">
          <div class="display-2 font-weight-thin">
            {{ price }}
          </div>
          <div class="caption">monthly</div>
        </div>
      </v-card>
      <v-card-text>
        <slot></slot>        
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  props: ['price', 'color', 'dark']
};
</script>

<style scoped></style>
